<template>
  <base-view
    title="Plantillas"
    description="Plantillas y layouts utilizados por el sistema."
    header-type="simplified"
    background="bg-gradient-black-to-blue"
  >
    <b-overlay :show="loading">
      <template #overlay>
        <div class="text-center">
          <loading message="Obteniendo archivo, por favor espere..."></loading>
        </div>
      </template>

      <h4 class="mb-0 mt-5">Plantillas usadas por el sistema.</h4>
      <hr class="mt-2 mb-4" />

      <b-row class="row">
        <b-col cols="12" lg="4" class="mb-4" v-for="item in layouts" :key="item.id">
          <!-- Layout para nóminas -->
          <div class="card lift lift-sm h-100 my-cursor">
            <div class="card-body">
              <h5 class="card-title mb-2" :class="item.textColor">
                <i class="mr-2" :data-feather="item.icon"></i>
                {{ item.title }}
              </h5>
              <p class="card-text mb-1">
                {{ item.desc }}
              </p>
            </div>
            <div class="card-footer">
              <div class="small text-muted" @click="onDownload(item.filename, item.ext)">{{ item.filename }}</div>
              <div class="small text-muted" @click="onDownload(item.example, item.ext)">{{ item.example }}</div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </base-view>
</template>

<script>
import service from '@/api/service'

const DOWNLOAD_LAYOUT = 'api/download/plantillas'

export default {
  name: 'PlantillasRecursosHumanosView',

  data () {
    return {
      layouts: [
        {
          title: 'Layout movimientos nómina',
          desc: 'Formato para importación de conceptos emitidos por la nómina quincenal.',
          filename: 'LAYOUT_MOVIMIENTOS_NOMINA.xlsx',
          example: 'EJEMPLO_MOVIMIENTOS_NOMINA.xlsx',
          ext: 'xlsx',
          textColor: 'text-primary',
          icon: 'layers'
        },
        {
          title: 'Layout para 3% sobre nómina',
          desc: 'Formato para importación del impuesto del 3% sobre nómina (capítulo 3000).',
          filename: 'LAYOUT_3%_NOMINA.xlsx',
          example: 'EJEMPLO_3%_NOMINA.xlsx',
          ext: 'xlsx',
          textColor: 'text-yellow',
          icon: 'layers'
        },
        {
          title: 'Layout para IMSS',
          desc: 'Formato para importación de IMSS, RCV e Infonavit.',
          filename: 'LAYOUT_IMSS.xlsx',
          example: 'EJEMPLO_IMSS.xlsx',
          ext: 'xlsx',
          textColor: 'text-purple',
          icon: 'layers'
        },
        {
          title: 'Layout para prima de antigüedad',
          desc: 'Formato para importación de primas de antigüedad, pago de marcha y pensión.',
          filename: 'LAYOUT_PRIMAS.xlsx',
          example: 'EJEMPLO_PRIMAS.xlsx',
          ext: 'xlsx',
          textColor: 'text-green',
          icon: 'layers'
        },
        {
          title: 'Layout para finiquitos',
          desc: 'Formato para importación de conceptos del finiquito.',
          filename: 'LAYOUT_FINIQUITOS.xlsx',
          example: 'EJEMPLO_FINIQUITOS.xlsx',
          ext: 'xlsx',
          textColor: 'text-red',
          icon: 'layers'
        },
        {
          title: 'Layout para seguro de vida',
          desc: 'Formato para importación de seguros de vida.',
          filename: 'LAYOUT_SEGURO_VIDA.xlsx',
          example: 'EJEMPLO_SEGURO_VIDA.xlsx',
          ext: 'xlsx',
          textColor: 'text-cyan',
          icon: 'layers'
        },
        {
          title: 'Layout para aguinaldos',
          desc: 'Formato para importación de aguinaldos.',
          filename: 'LAYOUT_AGUINALDOS.xlsx',
          example: 'EJEMPLO_AGUINALDOS.xlsx',
          ext: 'xlsx',
          textColor: 'text-orange',
          icon: 'layers'
        }
      ],

      loading: false
    }
  },

  methods: {
    async onDownload (filename, extension) {
      this.loading = true

      const filepath = 'recursos_humanos'
      const { data } = await service({
        _method: 'GET',
        url: `${DOWNLOAD_LAYOUT}`,
        responseType: 'blob',
        params: { filepath, filename }
      })

      this.loading = false

      this.$onDownload(data, `${filename}.${extension}`)
    }
  }
}
</script>
